import React from 'react';
import { MultiCombobox } from '../../../components/uie-fix'
import { usePageProps } from '../../../context';
import { ApplicationsPageProps, useFilterField } from '../../../hooks';

export const NationalityFilter = () => {
  const nationalityFilter = useFilterField('nationality');
  const { referenceData } = usePageProps<ApplicationsPageProps>();

  const nationalities = React.useMemo(
    () =>
      Object.entries(referenceData.nationalities)
        .map(([k, v]) => ({ label: v.nationalityLabel, value: k }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [referenceData.nationalities]
  );

  return (
    <div aria-label="Nationality Filter">
      <MultiCombobox
        value={nationalityFilter.value}
        aria-label="Nationality"
        placeholder="Nationality"
        onChange={(selectedItems) => nationalityFilter.setValue(selectedItems.filter((s) => !!s.trim()))}
        placeholderVariant="indicator"
      >
        {Object.entries(nationalities).map(([id, { label, value }]) => (
          <MultiCombobox.Option label={label} value={value} key={id} />
        ))}
      </MultiCombobox>
    </div>
  );
};
