// import { GetProgramResponse } from '@backend/students-api/routes/programs/{id}/GET/types'
import { SimpleResponse } from '@applyboard/school-backend-lib'
import { ApiAgentRequest, request } from '../../utils/request'
import { useAuthQuery } from '../auth'

// TODO: Move to BE?
type StudentProgram = {
  id: string
  name: string
  code: string
  url?: string
}

type StudentProgramResource = {
  id: string
  type: 'student_program'
  attributes: StudentProgram
}

type GetProgramResponse = SimpleResponse<StudentProgramResource>
// END - Move to BE?

interface GetProgramProps {
  id?: string
}

export function useGetProgram({ id }: GetProgramProps) {
  const { isLoading, data } = useAuthQuery(['program', id], async () => {
    if (!id) return undefined

    return await request<GetProgramResponse>(
      new ApiAgentRequest(`/programs/${id}`, {
        method: 'GET',
      }),
      { isExpectedResponse: (res): res is GetProgramResponse => res },
    )
  })

  return {
    isLoadingProgram: isLoading,
    program: data?.data,
  }
}
