import { RawApplicationResponse } from '../../../hooks'
import { AdditionalDocumentsTab } from './AdditionalDocumentsTab'
import { ContactInformationTab } from './ContactInformationTab'
import { EducationHistoryTab } from './EducationHistoryTab'
import { LanguageProficiencyTab } from './LanguageProficiencyTab'
import { PersonalInformationTab } from './PersonalInformationTab'
import { StatusAndCitizenshipTab } from './StatusAndCitizenshipTab'
import { ApplicationForm } from './ApplicationForm'
import { FileData, PersonalInformation } from '@applyboard/school-applications-types-lib'
import { StudentApplication } from '../types'
import { SetAllFieldsOptional } from '@applyboard/school-backend-lib'
import { Ref } from 'react'

type ApplicationFormsProps = {
  disabled?: boolean
  selectedTab: number
  application: StudentApplication
  onSuccess: (response?: RawApplicationResponse) => void
  onError?: (error: string) => void
  submitApplication?: () => void
  currentIntakeAvailable?: boolean
  onIntakeUnavailable?: () => void
  onCreateApplication?: (programIntakeId: string, personalInformation: SetAllFieldsOptional<PersonalInformation>) => void | undefined
  isCreatingApplication?: boolean
  focusRef?: Ref<HTMLButtonElement> | null
}

export function ApplicationForms(props: ApplicationFormsProps) {
  const onError = (err: Error) => {
    if (err instanceof Error) {
      props.onError?.(err.message)
    }
  }

  return (
    <ApplicationForm fileData={props.application?.attributes?.files as FileData}>
      <FormTab
        application={props.application}
        disabled={props.disabled}
        onError={onError}
        onSuccess={props.onSuccess}
        selectedTab={props.selectedTab}
        submitApplication={props.submitApplication}
        currentIntakeAvailable={props.currentIntakeAvailable}
        onIntakeUnavailable={props.onIntakeUnavailable}
        onCreateApplication={props.onCreateApplication}
        isCreatingApplication={props.isCreatingApplication}
        focusRef={props.focusRef}
      />
    </ApplicationForm>
  )
}

function FormTab(
  props: Omit<ApplicationFormsProps, 'onError'> & {
    onError: (err: Error) => void
  },
) {
  switch (props.selectedTab) {
    case 7:
    case 6:
      return (
        <AdditionalDocumentsTab
          application={props.application}
          onSuccess={props.onSuccess}
          onError={props.onError}
          submitApplication={props.submitApplication}
          disabled={props.disabled}
          focusRef={props.focusRef}
          currentIntakeAvailable={props.currentIntakeAvailable}
          onIntakeUnavailable={props.onIntakeUnavailable}
        />
      )
    case 5:
      return (
        <LanguageProficiencyTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          currentIntakeAvailable={props.currentIntakeAvailable}
          onIntakeUnavailable={props.onIntakeUnavailable}
          formId={crypto.randomUUID()}
        />
      )
    case 4:
      return (
        <EducationHistoryTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          currentIntakeAvailable={props.currentIntakeAvailable}
          onIntakeUnavailable={props.onIntakeUnavailable}
        />
      )
    case 3:
      return (
        <StatusAndCitizenshipTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          currentIntakeAvailable={props.currentIntakeAvailable}
          onIntakeUnavailable={props.onIntakeUnavailable}
        />
      )
    case 2:
      return (
        <ContactInformationTab
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          currentIntakeAvailable={props.currentIntakeAvailable}
          onIntakeUnavailable={props.onIntakeUnavailable}
        />
      )
    default:
      return (
        <PersonalInformationTab
          onCreateApplication={props.onCreateApplication}
          onSuccess={props.onSuccess}
          application={props.application}
          onError={props.onError}
          disabled={props.disabled}
          currentIntakeAvailable={props.currentIntakeAvailable}
          onIntakeUnavailable={props.onIntakeUnavailable}
          isCreatingApplication={props.isCreatingApplication}
        />
      )
  }
}
