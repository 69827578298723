import { useOktaAuth } from '@okta/okta-react'
import { useAuthToken } from './auth'

export function useLoggedInUser(): { username?: string } {
  const { getAccessTokenPayload } = useAuthToken()
  const useOkta = useOktaAuth()

  function getCognitoUsername() {
    return getAccessTokenPayload()?.['cognito:username']
  }

  function getOktaUsername() {
    return getAccessTokenPayload()?.sub
  }

  return {
    username: useOkta ? getOktaUsername() : getCognitoUsername(),
  }
}
