import { useMutation } from '@tanstack/react-query'
import { ConfirmUserParams } from '@applyboard/school-backend-lib'
import { ApiAgentRequest, request } from '../utils'

export function useSignUpConfirmation() {
  const { isPending, mutate } = useMutation({
    mutationFn: async (params: ConfirmUserParams) => {
      return await request(
        new ApiAgentRequest(`/pre/users/${params.username}/confirm`, {
          method: 'POST',
          body: {
            temporaryPassword: params.temporaryPassword,
            newPassword: params.newPassword,
          },
        }),
        {
          isExpectedResponse: (res): res is unknown => true,
        },
      )
    },
  })

  return {
    isConfirmingSignUp: isPending,
    signUpConfirmation: mutate,
  }
}
