import { Security } from '@okta/okta-react'
import { PropsWithChildren } from 'react'
import { useGetSpaConfig } from '../hooks/useGetSpaConfig'
import { Loading } from '../components/Loading'
import OktaAuth from '@okta/okta-auth-js'
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext'

export function ConfigProvider(props: PropsWithChildren) {
  const { config, isLoading } = useGetSpaConfig()

  if (isLoading) {
    return <Loading />
  }

  const isOktaEnabled = !!config

  if (!isOktaEnabled) {
    return <>{props.children}</>
  }

  const restoreOriginalUri: RestoreOriginalUriFunction = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    window.location.replace(originalUri || '/')
  }

  const oktaAuthInit = new OktaAuth({
    issuer: config?.issuer || '',
    clientId: config?.clientId || '',
    redirectUri: window.location.origin + config?.redirectUri,
  })

  return (
    <Security oktaAuth={oktaAuthInit} restoreOriginalUri={restoreOriginalUri}>
      {props.children}
    </Security>
  )
}
