import { Button, Flex, Text } from '@applyboard/crystal-ui'
import { IntakeSearchResource } from '@backend/agents-ui-api/resources/intake-search/types'
import { RefinedVersionedApplicationResourceWithFiles } from '@applyboard/school-applications-types-lib'

type ProgramCardButtonProps = {
  loading?: boolean
  disabled?: boolean
  onClick: () => void
  intakeSearch: IntakeSearchResource
  currentApplication?: RefinedVersionedApplicationResourceWithFiles['attributes']
  isDesktop?: boolean
}

export function ProgramCardButton(props: ProgramCardButtonProps) {
  
  if (props.intakeSearch.attributes.intake.status === 'OPEN') {
    if (props.currentApplication) {
      if (props.currentApplication.programSelected?.programIntakeId === props.intakeSearch.id) {
        return (
          <Flex pb={!props.isDesktop ? 5 : undefined} justify={!props.isDesktop ? "center" : undefined}>
            <Text variant="labelL" contrast="low">
              Currently Selected
            </Text>
          </Flex>
        )
      }
    }

    return (
      <Button
        intent="primary"
        emphasis={props.isDesktop ? 'highlighted' : 'outlined'}
        width={props.isDesktop ? 'hug' : 'fill'}
        onClick={props.onClick}
        disabled={props.disabled}
        loading={props.loading}
      >
        Apply
      </Button>
    )
  }

  return (
    <Flex pb={{ xs: 5, md: 0 }} justify={{ xs: 'center', md: 'start' }}>
      <Text variant="labelL" contrast="low">
        Not Available
      </Text>
    </Flex>
  )
}
