import { Flex, GridAlpha, Text } from '@applyboard/crystal-ui'
import { ApplicationDetailFile } from '../ApplicationDetailFile'
import { format } from 'date-fns'
import { FileData } from '@applyboard/school-applications-types-lib'
import { getFile } from '../utils'
import { VersionedApplicationSanitizedWithFiles } from '@backend/sanitize/application'

type ApplicationDetailAdditionalDocumentsProps = Readonly<{
  files: FileData | undefined,
  application: VersionedApplicationSanitizedWithFiles
}>

export function ApplicationDetailAdditionalDocuments(
  props: ApplicationDetailAdditionalDocumentsProps,
) {
  return (
    <>
      {props.files && (
        <GridAlpha columns={{ xs: 'auto', lg: 'repeat(2, 1fr)', }} columnGap={6} rowGap={6}>
          {Object.keys(props.files).map((key: string) => (
            props.files && props.files[key] ? (
              <GridAlpha.Item key={getFile(key, props.application)?.hash}>
                <Flex direction="column" gap={2}>
                  <ApplicationDetailFile name={getFile(key, props.application)?.name} url={getFile(key, props.application)?.url} />
                  <Text variant="labelS" contrast="mid">
                    Uploaded on {format(getFile(key, props.application)?.uploadedAt, 'dd MMM yyyy')}
                  </Text>
                </Flex>
              </GridAlpha.Item>
            ) : <></>
          ))}
        </GridAlpha>
      )}
      {(!props.files || !Object.keys(props.files).length) && <Text>There are no files available yet.</Text>}
    </>
  )
}
