import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuthManager } from '../../hooks'
import { useOktaAuth } from '@okta/okta-react'
import { Loading } from '../Loading'

export const LOCAL_STORAGE_REDIRECT_KEY = 'redirect'

export function ProtectedRoute({ isSignupLoginRoute }: { isSignupLoginRoute?: boolean }) {
  const location = useLocation()
  const { isLoggedIn } = useAuthManager()
  const useOkta = useOktaAuth()

  if (useOkta) {
    if (!useOkta.authState) return <Loading />

    return useOkta.authState.isAuthenticated ||
      (isSignupLoginRoute && location.pathname !== '/login') ? (
      <Outlet />
    ) : (
      (useOkta.oktaAuth.signInWithRedirect({ originalUri: '/' }), null)
    )
  }

  if (isLoggedIn()) {
    if (isSignupLoginRoute) return <Navigate to={'/'} />
    return <Outlet />
  }

  // prevent redirect loop
  if (isSignupLoginRoute) return <Outlet />

  window.localStorage.setItem(LOCAL_STORAGE_REDIRECT_KEY, location.pathname + location.search)
  return <Navigate to={'/login'} state={{ from: location.pathname + location.search }} />
}
