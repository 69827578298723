import { Button, Flex } from '@applyboard/crystal-ui'
import {
  ApplicationDetailCardAcceptDialog,
  ApplicationDetailCardAcceptDialogProps,
} from './ApplicationDetailCardAcceptDialog'
import {
  ApplicationDetailCardRejectDialog,
  ApplicationDetailCardRejectDialogProps,
} from './ApplicationDetailCardRejectDialog'
import {
  DecisionResponseType,
  DocumentType
} from "@applyboard/school-applications-types-lib";

type ApplicationDetailCardActionsProps = Readonly<{
  onOfferConditionsClick?: () => void
  acceptDialog?: ApplicationDetailCardAcceptDialogProps | null
  rejectDialog?: ApplicationDetailCardRejectDialogProps | null
  loading?: boolean
  status?: DecisionResponseType
  documentType?: DocumentType
}>

export function ApplicationDetailCardActions(props: ApplicationDetailCardActionsProps) {
  return (
    <>
      {props.acceptDialog && props.rejectDialog && props.status === DecisionResponseType.PENDING ? (
        <Flex gap={2}>
          <Flex.Item basis="50%">
            <ApplicationDetailCardAcceptDialog
              programName={props.acceptDialog.programName}
              campusName={props.acceptDialog.campusName}
              intakeTermName={props.acceptDialog.intakeTermName}
              onSubmit={props.acceptDialog.onSubmit}
              loading={props.loading}
            />
          </Flex.Item>
          <Flex.Item basis="50%">
            <ApplicationDetailCardRejectDialog
              onSubmit={props.rejectDialog.onSubmit}
              loading={props.loading}
            />
          </Flex.Item>
        </Flex>
      ) : null}
      {props.onOfferConditionsClick && (props.status === DecisionResponseType.PENDING || props.documentType === DocumentType.FINAL_OFFER_LETTER || props.documentType === DocumentType.WAITLIST_LETTER) ? (
        <Flex>
          <Button
            intent="primary"
            emphasis="outlined"
            width="fill"
            onClick={props.onOfferConditionsClick}
            disabled={props.loading}
          >
            Offer Conditions
          </Button>
        </Flex>
      ) : null}
    </>
  )
}
