import { CrystalProvider } from '@applyboard/crystal-ui'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ProtectedRoute } from './components/ProtectedRoute'
import {
  ApplicationsListPage,
  CreateApplicationPage,
  LoginPage,
  NotFoundPage,
  ViewApplicationPage,
} from './pages'
import initializers from './config/initializers'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { SignUpConfirmationPage } from './pages/SignUpConfirmationPage'
import { ConfigProvider } from './utils/ConfigProvider'
import { LoginCallback } from '@okta/okta-react'

initializers.init()
const queryClient = new QueryClient()

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <script src="https://payment.flywire.com/assets/js/checkout.js"></script>
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <CrystalProvider>
          <ConfigProvider>
            <BrowserRouter>
              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route path="/" element={<ApplicationsListPage />} />
                  <Route path="/applications/new" element={<CreateApplicationPage />} />
                  <Route path="/applications/:id" element={<ViewApplicationPage />} />
                </Route>
                <Route element={<ProtectedRoute isSignupLoginRoute />}>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/signup/:username/confirm" element={<SignUpConfirmationPage />} />
                </Route>
                <Route path="/login/callback" element={<LoginCallback />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </BrowserRouter>
          </ConfigProvider>
        </CrystalProvider>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
