import React from 'react';
import { MultiSelect } from '@applyboard/crystal-ui';
import { ApplicationsPageProps, useFilterField } from '../../../hooks';
import { usePageProps } from '../../../context';

export const StatusFilter = () => {
  const { value, setValue } = useFilterField('status');
  const { referenceData } = usePageProps<ApplicationsPageProps>(); 

  const statuses = React.useMemo(
    () => {
      return Object.entries(referenceData.applicationStatuses)
        .map(([k, v]) => ({ label: v.label, value: v.groupCode }))
        .filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.value === item.value
          )))
    },
    [referenceData.applicationStatuses]
  );

  return (
    <div aria-label="Status Filter">
      <MultiSelect
        value={value}
        aria-label="Status"
        placeholder="Status"
        onChange={setValue}
        emphasis="highlighted"
        placeholderVariant="indicator"
      >
        {statuses.map((status) => (
          <MultiSelect.Option label={status.label} value={status.value} key={status.value} />
        ))}
      </MultiSelect>
    </div>
  );
};
