import { Button, Flex } from "@applyboard/crystal-ui";
import { IntakeSearchResource, PaginationCollectionResponse } from "@backend/agents-ui-api/resources/intake-search/types";
import { ProgramCard } from "../ProgramCard";
import { RefinedVersionedApplicationResourceWithFiles } from "@applyboard/school-applications-types-lib";
import { PaginationDetails } from "../PaginationDetails";
import { ChevronLeftOutlineIcon, ChevronRightOutlineIcon } from "@applyboard/ui-icons";

interface ProgramListMobileProps {
  onSubmit: (intake: IntakeSearchResource) => void;
  setSelectedIntakeId: (intakeId: string) => void;
  updateFilters: (filters: Record<string, string>) => void;
  intakeSearch: IntakeSearchResource[];
  intakeSearchMeta: PaginationCollectionResponse<RefinedVersionedApplicationResourceWithFiles>['meta'] | undefined;
  loading: boolean;
  selectedIntakeId: string | undefined;
  currentApplication: RefinedVersionedApplicationResourceWithFiles['attributes'];
}

export function ProgramListMobile(props: ProgramListMobileProps) {
  
  return(
    <Flex direction="column" gap={4}>
      {props.intakeSearch.map((intake: IntakeSearchResource) => (
        <ProgramCard
          key={`intake-${intake.id}-1`}
          disabled={props.selectedIntakeId !== intake.id && props.loading}
          loading={props.selectedIntakeId === intake.id && props.loading}
          onSubmit={() => {
            props.setSelectedIntakeId(intake.id)
            props.onSubmit(intake)
          }}
          intake={intake}
          currentApplication={props.currentApplication}
        />
      ))}
      {props.intakeSearchMeta?.pagination ? (
        <Flex align="center" justify="between" pt={2}>
          <PaginationDetails pagination={props.intakeSearchMeta.pagination} />
          <Flex gap={2}>
            <Button
              aria-label="Previous page"
              leadIcon={ChevronLeftOutlineIcon}
              emphasis="highlighted"
              onClick={() => {
                if (!props.intakeSearchMeta) return;
                if (props.intakeSearchMeta.pagination.currentPage - 1 >= 1) {
                  props.updateFilters({
                    pageNumber: `${props.intakeSearchMeta.pagination.currentPage - 1}`,
                  })
                }
              }}
            />
            <Button
              aria-label="Next page"
              leadIcon={ChevronRightOutlineIcon}
              emphasis="highlighted"
              onClick={() => {
                if (!props.intakeSearchMeta) return;
                if (
                  props.intakeSearchMeta.pagination.currentPage + 1 <=
                  Math.ceil(props.intakeSearchMeta.pagination.total / props.intakeSearchMeta.pagination.perPage)
                ) {
                  props.updateFilters({
                    pageNumber: `${props.intakeSearchMeta.pagination.currentPage + 1}`,
                  })
                }
              }}
            />
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  )
}