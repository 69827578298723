import { useNavigate } from 'react-router-dom'
import { OAuth2TokenResponse } from '@applyboard/school-backend-lib'
import { AuthStorage } from '../../utils'
import { useAuthToken, useLogin, useRefreshToken } from './'
import { useQueryClient } from '@tanstack/react-query'
import { useOktaAuth } from '@okta/okta-react'

export function useAuthManager() {
  const navigate = useNavigate()
  const { getAccessTokenPayload } = useAuthToken()
  const queryClient = useQueryClient()
  const { login, isLoggingIn } = useLogin({ setCredentials })
  const { refreshAccessToken, isRefreshingAccessToken, refreshAccessTokenError } = useRefreshToken({
    getRefreshToken: AuthStorage.getRefreshToken,
    setCredentials,
  })

  const useOkta = useOktaAuth()

  function isLoggedIn(): boolean {
    if (
      !!AuthStorage.getAccessToken() &&
      !!AuthStorage.getRefreshToken() &&
      getAccessTokenPayload()
    )
      return true
    return false
  }

  function setCredentials(loginData: OAuth2TokenResponse) {
    AuthStorage.setAuthCredentials(loginData)
  }

  function signout() {
    if (useOkta) {
      useOkta.oktaAuth.signOut()
    } else {
      AuthStorage.removeAuthCredentials()
      navigate('/login')
    }
    queryClient.clear()
  }

  return {
    isLoggedIn,
    isLoggingIn,
    isRefreshingAccessToken,
    login,
    refreshAccessToken,
    refreshAccessTokenError,
    signout,
  }
}
